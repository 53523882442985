import handleError, { handleSuccess } from '~/composables/api/handleResponse'
import type { PromiseResponseData } from '~/composables/types/api/apiResponse'
import type { UploadBranchClusterParams } from '~/composables/types/api/searchDiscover/generateTransfer'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function uploadBranchClusterFile(
    data: UploadBranchClusterParams,
    options?: { signal: AbortSignal }
): PromiseResponseData<object> {
    const url = `/branch-clusters`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        const response = <any>await fetchSdApi(url, {
            ...options,
            method: 'POST',
            body: data.formData,
        })

        if (response?.error) {
            return handleError(response)
        }

        return handleSuccess<object>({})
    } catch (error: any) {
        return handleError(error)
    }
}
